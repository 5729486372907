import React, { useCallback, useEffect } from 'react'

import Link from 'next/link'
import { useRouter } from 'next/router'

import { Alert, AlertTitle, Pagination, Skeleton } from '@material-ui/lab'
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core'

import _ from 'lodash'
import { useIpLocation } from '@assuranceiq/react-components'

import {
  AgentLOI,
  AgentLOIs,
  AgentWithBio,
  ApiAgentsSearchResults,
  UsState,
  UsStates
} from '@/common/types'
import { PageHeader } from '@/components/page-header'
import { getLoiName, getProductNameByLoi } from '@/utils/loi'
import { listAgents } from '@/common/api'

import styles from './profile-search.module.scss'

const ITEMS_PER_PAGE = 20

export function ProfileSearch() {
  const router = useRouter()
  const urlParams = new URLSearchParams(
    typeof window !== 'undefined' ? document.location.search : ''
  )
  const ipLocation = useIpLocation()
  const ipState = _.get(ipLocation, 'subdivisions[0].iso_code')

  const [loi, setLoi] = React.useState<AgentLOI | undefined>()
  const [state, setState] = React.useState<UsState | undefined>()
  const isSubmitEnabled = !!loi && !!state

  const [searchResults, setSearchResults] = React.useState<ApiAgentsSearchResults<AgentWithBio>>()
  const [currentPage, setCurrentPage] = React.useState<number>(-1)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  async function onSubmit() {
    if (!isSubmitEnabled) {
      return
    }
    setCurrentPage(1)
  }

  const fetchPage = useCallback(
    async function (page: number) {
      if (!loi || !state) {
        return
      }
      setIsLoading(true)
      setCurrentPage(page)
      const res = await listAgents<AgentWithBio>(
        { page, pageSize: ITEMS_PER_PAGE, includeBio: true, loi, state },
        true
      )
      setSearchResults(res)
      setIsLoading(false)
    },
    [loi, state]
  )

  useEffect(
    () => {
      if (currentPage === -1 || _.isEmpty(loi) || _.isEmpty(state)) {
        return
      }
      async function maybeFetchPage() {
        const params = {
          loi,
          state,
          page: currentPage
        }

        router.push(
          {
            pathname: '/',
            query: params
          },
          undefined,
          { shallow: true }
        )
        await fetchPage(currentPage)
      }

      maybeFetchPage()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loi, state, currentPage, fetchPage]
  )

  useEffect(() => {
    const stateParam = urlParams.get('state')

    if (stateParam === 'nearme' || state === undefined) {
      setState(ipState)
    }
  }, [state, ipState, urlParams])

  // fetch first page on load if loi&state were given
  useEffect(
    () => {
      const loiParam = urlParams.get('loi')
      if (loiParam) {
        setLoi(loiParam as AgentLOI)
      }

      const stateParam = urlParams.get('state')
      if (stateParam) {
        setState(stateParam === 'nearme' ? ipState : (stateParam as UsState))
      }

      let pageParam = urlParams.get('page')
      if (loiParam && stateParam && !pageParam) {
        // if both loi&state are given and no page is given - start the search
        pageParam = '1'
      }
      if (pageParam) {
        setCurrentPage(parseInt(pageParam as string, 10))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  function handleProductChange(ev) {
    setLoi(ev.target.value)
    setCurrentPage(-1)
  }

  function handleStateChange(ev) {
    setState(ev.target.value)
    setCurrentPage(-1)
  }

  return (
    <div className={styles.root}>
      <PageHeader>
        <Typography className={styles.headerTitle} variant='h4' display='block' gutterBottom>
          Your #1 source to find independent insurance agents
        </Typography>
        <Typography className={styles.headerSubtitle} variant='body1' display='block'>
          Over a thousand agents ready to assist you!
        </Typography>
      </PageHeader>

      <Container maxWidth='md'>
        <form onSubmit={onSubmit} className={styles.form}>
          <FormControl className={styles.select}>
            <InputLabel id='insuranceProductLabel'>What are you looking for?</InputLabel>
            <Select
              labelId='insuranceProductLabel'
              id='insuranceProduct'
              value={loi || ''}
              onChange={handleProductChange}
            >
              {_.keys(AgentLOIs).map(loi => (
                <MenuItem key={loi} value={loi}>
                  {getProductNameByLoi(loi)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={styles.select}>
            <InputLabel id='insuranceStateLabel'>What state do you reside in?</InputLabel>
            <Select
              labelId='insuranceStateLabel'
              id='insuranceState'
              onChange={handleStateChange}
              value={state || ''}
            >
              {_.keys(UsStates).map(stateShort => (
                <MenuItem key={stateShort} value={stateShort}>
                  {_.capitalize(UsStates[stateShort])}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            className={styles.submitButton}
            variant='contained'
            size='large'
            color='primary'
            disabled={!isSubmitEnabled}
            onClick={onSubmit}
          >
            Find Agents
          </Button>
        </form>

        <Grid
          container
          spacing={3}
          alignItems='flex-start'
          justify='flex-start'
          className={styles.results}
        >
          {isLoading && _.times(6, n => <AgentSearchResultSkeleton key={n} />)}
          {!isLoading &&
            searchResults &&
            searchResults.results &&
            _.map(searchResults.results, agent => (
              <AgentSearchResult key={agent.assurance_user_id} agent={agent} />
            ))}
        </Grid>
        {!isLoading && searchResults && searchResults.count === 0 && (
          <Box mt={2}>
            <Alert severity='info'>
              <AlertTitle>No agents found</AlertTitle>
              Sorry, there are no agents available in that state.
            </Alert>
          </Box>
        )}
        {searchResults && searchResults.count > ITEMS_PER_PAGE && (
          <Pagination
            boundaryCount={1}
            siblingCount={1}
            page={currentPage}
            count={Math.ceil(searchResults.count / ITEMS_PER_PAGE)}
            shape='rounded'
            onChange={(ev, page) => fetchPage(page)}
          />
        )}
      </Container>
    </div>
  )
}

function AgentSearchResultSkeleton() {
  return (
    <Grid item md={2} sm={3} xs={6}>
      <Skeleton variant='circle' className={styles.avatar} />
      <Skeleton variant='text' />
      <Skeleton variant='text' />
    </Grid>
  )
}

interface AgentSearchResultProps {
  agent: AgentWithBio
}

function AgentSearchResult({ agent }: AgentSearchResultProps) {
  const username = agent.email.replace(/@.*/, '')

  return (
    <Grid item md={2} sm={3} xs={6}>
      <Link href='/profile_page/[id]/' as={`/profile_page/${username}/`}>
        <a className={styles.agent}>
          <Avatar
            src={agent.bio.social_media_profile_picture}
            className={styles.avatar}
            alt='profile picture'
          />
          <Typography className={styles.name} variant='body1'>
            {agent.first_name} {agent.last_name}
          </Typography>
          <Typography variant='body2'>{getLoiName(agent.loi)}&nbsp;Agent</Typography>
        </a>
      </Link>
    </Grid>
  )
}
