import React from 'react'

import {
  Button,
  ButtonProps,
  Card,
  CardActions,
  CardContent,
  Link,
  Typography
} from '@material-ui/core'

import _ from 'lodash'

import { API_ENDPOINT } from '@/common/environment-variables'
import { Agent, LOI, LOIs } from '@/common/types'

import styles from './quote-button.module.scss'

interface QuoteButtonProps extends ButtonProps {
  agent?: Agent
  loi: LOI
}

function getLOIProps(loi, agent) {
  const urlLoi = loi === LOIs.auto || loi === LOIs.home ? 'auto_home' : loi

  let ctaHref = `${API_ENDPOINT}/amp_ui/utm/${urlLoi}`
  if (agent) ctaHref += `/${agent.assurance_user_id}`

  return {
    title: _.upperFirst(loi),
    pictureUrl: `/images/quotes/${loi}.svg`,
    ctaHref: ctaHref
  }
}

export function QuoteButton(props: QuoteButtonProps) {
  const { agent, loi, ...rest } = props
  const { title, pictureUrl, ctaHref } = getLOIProps(loi, agent)

  return (
    <Button className={styles.root} variant='contained' href={ctaHref} {...rest}>
      <div className={styles.container}>
        <img src={pictureUrl} alt={title} />
        <div>
          <Typography variant='caption' className={styles.heading}>
            {title}
          </Typography>
        </div>
      </div>
    </Button>
  )
}

interface QuoteBoxProps {
  agent?: Agent
  loi: LOI
  description: string
}

export function QuoteBox(props: QuoteBoxProps) {
  const { agent, loi, description } = props
  const { title, pictureUrl, ctaHref } = getLOIProps(loi, agent)
  let linkText = `Learn more about ${loi} insurance`
  if (loi === LOIs.medicare) {
    linkText = 'Learn more about insurance'
  }

  return (
    <Card className={styles.box} variant='outlined'>
      <CardContent className={styles.container}>
        <img src={pictureUrl} alt={title} />
        <Typography variant='subtitle2' className={styles.heading} gutterBottom>
          {title}
        </Typography>
        <Typography variant='body2' gutterBottom>
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Link className={styles.link} href={ctaHref}>
          {linkText}&hellip;
        </Link>
      </CardActions>
    </Card>
  )
}
