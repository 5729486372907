import React from 'react'

import Head from 'next/head'

import { DefaultLayout, siteTitle } from '@/layouts'
import { ProfileSearch } from '@/components/profile-search'

export default function Home() {
  return (
    <DefaultLayout hideAppBar>
      <Head>
        <title>Search Insurance Agent Profiles | {siteTitle}</title>
      </Head>
      <ProfileSearch />
    </DefaultLayout>
  )
}
