import React from 'react'

import { Box, Card, Container, Typography } from '@material-ui/core'

import _ from 'lodash'

import { Agent, LOIs } from '@/common/types'
import { QuoteButton } from '@/components/quote-button'

import styles from './page-header.module.scss'

interface PageHeaderProps {
  agent?: Agent
  children: React.ReactNode
}

export function PageHeader(props: PageHeaderProps) {
  const { agent, children } = props

  return (
    <Box className={styles.root}>
      <Container maxWidth='md' className={styles.container}>
        <div className={styles.content}>{children}</div>
        <Card className={styles.loiCard}>
          <Typography variant='h6' gutterBottom>
            Get a Quote
          </Typography>
          <Box display='flex' className={styles.quoteButtons}>
            {_.values(LOIs).map(loi => (
              <QuoteButton key={loi} agent={agent} loi={loi} variant='outlined' />
            ))}
          </Box>
        </Card>
      </Container>
    </Box>
  )
}
